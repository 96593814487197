.Navigation-Inner a {
    display: block;
}
.Navigation {
    margin-top: 1em;
    margin-left: 1em;

    /*position: fixed;*/
    /*max-width: 15ch;*/
}
.Navigation a {
    padding: 0.5em 0.5em 0.5em 0.5em;
    text-decoration: none;
    font-size: 1.25em;
    color: rgb(100,100,100);
    text-align: center;
    /*transition: 0.3s;*/
}

.Navigation a:active {
    color: black;
}
.Navigation a:visited {
    color: rgb(100,100,100);
}

.Navigation a:hover {
    color: black;
    /*font-weight: bold;*/
    text-decoration: none;
}

.Navigation-Inner {
    text-align: left;
    position: fixed;
    max-width: calc(100vw - 67vw);
}

.Navigation a.active {
    color: black;
}

@media (min-width: 1100px) {
    .Navigation-Inner {
        left: calc(100vw - 66vw - 16vw - 8vw);
    }
}

@media (max-width: 800px) {
    .Navigation {
        margin-left: 0.1em;
    }
    .Navigation a {
        font-size: 1em;
    }
}

@media (max-height: 800px) {
    .Navigation-Inner {
        /*background-color: yellow;*/
    }
    .Navigation-Inner a {
        line-height: 1.5;
        padding-bottom: 0.25em;
    }
}


@media (max-height: 480px) {
    .Navigation-Inner {
        top: 30vh;
        /*background-color: yellow;*/
    }
    .Navigation-Inner a {
        line-height: 1;
        padding-bottom: 0.25em;
    }
}