.App {
  width: 100%;
  height: 100%;
}

.App-Inner {
  display: flex;
  flex-direction: row;
}

.App-Inner > div:first-child {
  flex: 3;
}

.App-Footer {
  width: 100%;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.App-Copyright {
  margin-left: 0.5em;
  flex: 1;
  color: rgb(150, 150, 150)
}

.App-Header {
  flex: 1;
  text-align: right;
  margin-right: 0.5em;
  color: rgb(150, 150, 150)
}

.App-Login {
  flex: 1;
  text-align: right;
  margin-right: 0.5em;
  color: rgb(150, 150, 150)
}

.App-Version {
  flex: 1;
  text-align: right;
  margin-right: 0.5em;
  color: rgb(150, 150, 150)
}


.App-Inner > div:last-child {
  flex: 11;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.Footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
  padding: 20px;
  font-size: 14px;
}

.Footer button {
  background: none;
  border: none;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
}

.App-Footer {
  width: 100%;
  display: flex;
  justify-content: center;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-Content-Container {
  max-width: 66vw;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.top-image {
  /*background-size: contain!important;*/
  background-size: cover !important;
  background-position-y: bottom !important;
  height: 30vh;
  width: 100%;
  /*position: absolute;*/
  margin: 0px;
  left: 0px;
}

@media (max-width: 800px) {
  .top-image {
    background: url("/src/assets/images/image1v1-max-width-800.jpg");
  }
}

@media (min-width: 800px)  and (max-width: 1200px){
  .top-image {
    background: url("/src/assets/images/image1v1-max-width-1200.jpg");
  }
}

@media (min-width: 1200px)  and (max-width: 1900px){
  .top-image {
    background: url("/src/assets/images/image1v1-max-width-1900.jpg");
  }
}

@media (min-width: 1900px)  and (max-width: 2400px){
  .top-image {
    background: url("/src/assets/images/image1v1-max-width-2400.jpg");
  }
}

@media (min-width: 2400px){
  .top-image {
    background: url("/src/assets/images/image1v1-max-width-max.jpg");
  }
}


