.Body-Inner {
    max-width: 80ch;
    margin-left: auto;
    margin-right: auto;
}

.Body-Inner > div > div:last-child {
    min-height: 287px;
}

span.menu-separator {
    border-bottom: 1px solid rgb(195,195,195);
    height: 1px;
    width: 50%;
    display: block;
    margin-left: 25%;
}
.Navigation-Inner > div {
    text-align: center;
}

span.section-separator {
    border-bottom: 1px solid rgb(195,195,195);
    height: 1px;
    width: 50%;
    display: block;
    margin-left: 25%;
    margin-top: 2.5em;
    margin-bottom: 2.5em;
}

html {
    scroll-behavior: smooth
}

